<template>
  <div class="reward-activity-product-block">
    <p class="card-title">適用活動商品設定</p>
    <el-form ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <el-select v-model="selectData.type" @change="onSelectChange">
        <el-option
          v-for="(item, index) in selectProductConfig"
          :key="index"
          :value="item.value"
          :label="item.label"
        />
      </el-select>
      <div class="flex justify-between items-center" style="max-width: 560px">
        <el-button class="underline" type="text" @click="showProductSelectModal = true">
          {{ chooseLabel }}
        </el-button>
        <el-button class="underline" style="color: var(--danger)" type="text" @click="removeAll">移除全部</el-button>
      </div>
      <el-form-item
        v-for="(value, key) in formData"
        :key="key"
        :prop="key"
      >
        <el-select
          v-model="formData[key]"
          popper-class="select-popper"
          placeholder="請選擇商品"
          @visible-change="advanceSelectClick(key)"
        >
          <el-option
            :label="findProductName(formData[key])"
            :value="formData[key]"
          />
        </el-select>
        <el-button class="underline" style="color: var(--danger); margin-left: 16px" type="text" @click="onProductRemove(key)">移除</el-button>
      </el-form-item>
    </el-form>

    <MemberShopProductAdvanceSelect
      v-if="showProductSelectModal"
      multiple
      :data="selectableProducts()"
      :typeOptions="productTypeConfig"
      @confirm="onAddProduct"
      @close="showProductSelectModal = false"
    />
  </div>
</template>

<script>
import { defineComponent, reactive, ref, set, del, onMounted, nextTick, computed, watch } from 'vue'
import { nanoid } from 'nanoid'
import { noEmptyRules } from '@/validation'
import { find, get } from 'lodash'
import MemberShopProductAdvanceSelect from '@/components/Select/MemberShopProductAdvanceSelect.vue'
import { productTypeConfig } from '@/config/memberShop'

export default defineComponent({
  name: 'RewardActivityProductBlock',
  components: { MemberShopProductAdvanceSelect },
  props: ['storeProducts', 'FormsContext', 'selectProductConfig', 'activityData'],
  setup (props) {
    const formRef = ref(null)
    const formData = reactive({})
    const selectData = reactive({
      type: props.activityData?.sourceItemsSettings[0]?.operator || 'EXCLUDE',
    })
    const sourceItemIds = ref([])
    const formRules = {}
    const showProductSelectModal = ref(false)
    const selectProduct = ref()

    const chooseLabel = computed(() => {
      const selectedItem = props.selectProductConfig.find((item) => {
        return item.value === selectData.type
      })
      return selectedItem.chooseLabel
    })

    const selected = () => {
      const values = []
      for (const key in formData) {
        values.push(formData[key])
      }
      return values
    }

    const selectableProducts = () => {
      const products = selected()
      return props.storeProducts.filter(p => !products.includes(p.id))
    }

    const onAddProduct = (products) => {
      if (selectProduct.value) {
        set(formData, selectProduct.value, products[0])
        selectProduct.value = null
        return
      }
      for (const product of products) {
        const id = nanoid(10)
        set(formData, `product_${id}`, product)
        set(formRules, `product_${id}`, [noEmptyRules()])
      }
    }

    const findProductName = (id) => {
      const product = find(props.storeProducts, { id })
      if (product) return product.name
      return ''
    }
    const advanceSelectClick = (key) => {
      showProductSelectModal.value = true
      selectProduct.value = key
    }

    const removeAll = () => {
      for (const key in formData) {
        del(formData, key)
        del(formRules, key)
      }
    }

    const onProductRemove = (key) => {
      del(formData, key)
      del(formRules, key)
    }

    const compactData = computed(() => {
      const data = {
        sourceItemsSettings: [
          {
            sourceItemType: 'memberStoreProduct',
            operator: get(selectData, 'type'),
            sourceItemIds: sourceItemIds.value,
          },
        ],
      }
      return { ...data }
    })

    const syncData = () => {
      const data = props.activityData
      const sourceItemsSettings = get(data, 'sourceItemsSettings')
      const ids = sourceItemsSettings[0].sourceItemIds
      set(selectData, 'type', sourceItemsSettings[0].operator)

      for (const product of ids) {
        const id = nanoid(10)
        set(formData, `product_${id}`, product)
        set(formRules, `product_${id}`, [noEmptyRules()])
      }
    }

    onMounted(async () => {
      await nextTick()

      if (get(props.activityData, 'id')) syncData()
      props.FormsContext.setFormData('product', { ...compactData.value })
    })

    const onSelectChange = () => {
      removeAll()
      props.FormsContext.setFormData('product', { ...compactData.value })
    }

    watch(formData, () => {
      sourceItemIds.value = Object.values(formData)
      props.FormsContext.setFormData('product', { ...compactData.value })
    })

    return {
      formRef,
      formData,
      formRules,
      showProductSelectModal,
      selectableProducts,
      productTypeConfig,
      onAddProduct,
      findProductName,
      advanceSelectClick,
      onProductRemove,
      selectData,
      chooseLabel,
      removeAll,
      onSelectChange,
    }
  },
})
</script>
