<template>
  <div class="member-shop-feedback-activity-edit">
    <div v-loading="loading" />
    <template v-if="!loading">
      <RewardActivityInfoBlock
        class="card-container form-wrapper"
        :FormsContext="FormsContext"
        :activityData="activityData"
      />
      <RewardActivityProductBlock
        class="card-container form-wrapper"
        :FormsContext="FormsContext"
        :activityData="activityData"
        :storeProducts="storeProducts"
        :selectProductConfig="selectProductConfig"
      />
      <RewardActivityTypeBlock
        class="card-container form-wrapper"
        :FormsContext="FormsContext"
        :typeSettingConfig="typeSettingConfig"
        :changeTypeHandler="changeTypeHandler"
        :activityData="activityData"
      />
      <RewardActivityRulesBlock
        class="card-container form-wrapper"
        :FormsContext="FormsContext"
        :rewardType="rewardType"
        :activityData="activityData"
      />
      <RewardActivityLimitBlock
        class="card-container form-wrapper"
        :FormsContext="FormsContext"
        :rewardType="rewardType"
        :activityData="activityData"
      />
    </template>

    <PageFixedFooter @cancel="onCancel" @confirm="onSubmit" />

    <WarningDialog
      v-if="showWarningDialog"
      title="提醒"
      width="560px"
      content="選擇「只針對特定商品」進行兌換活動請至少選擇一項特定商品"
      hideCancel
      @confirm="showWarningDialog = false"
      @close="showWarningDialog = false"
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router/composables'
import store from '@/store'
import PageFixedFooter from '@/components/Footer/PageFixedFooter.vue'
import RewardActivityInfoBlock from '@/components/RewardActivity/RewardActivityInfoBlock.vue'
import RewardActivityTypeBlock from '@/components/RewardActivity/RewardActivityTypeBlock.vue'
import RewardActivityRulesBlock from '@/components/RewardActivity/RewardActivityRulesBlock.vue'
import RewardActivityLimitBlock from '@/components/RewardActivity/RewardActivityLimitBlock.vue'
import RewardActivityProductBlock from './components/MemberShopFeedbackActivityProductBlock.vue'
import WarningDialog from '@/components/Dialog/WarningDialog.vue'
import FormsContext from './formsContext'
import { CreateRewardActivity, FindRewardActivity, UpdateRewardActivity } from '@/api/rewardActivity'
import { GetMemberShopProduct, GetMemberShopProductCount } from '@/api/memberShop'
import { getAllDataFromApi } from '@/utils/helper'
import notifyMessage from '@/config/notifyMessage'

export default defineComponent({
  name: 'MemberShopFeedbackActivityEdit',
  components: {
    PageFixedFooter,
    RewardActivityInfoBlock,
    RewardActivityTypeBlock,
    RewardActivityRulesBlock,
    RewardActivityLimitBlock,
    RewardActivityProductBlock,
    WarningDialog,
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const shopId = computed(() => store.getters.shop)
    const activityId = computed(() => route.params.id)
    const activityData = ref(null)
    const loading = ref(false)
    const storeProducts = ref([])
    const showWarningDialog = ref(false)

    const onCancel = () => {
      router.push({
        name: 'MemberShopFeedbackActivity',
      })
    }

    const typeSettingConfig = [
      {
        label: '點數',
        value: 'POINT',
        featureKey: 'admin.shopPoint.page',
      },
      {
        label: '回饋金',
        value: 'CASHBACK',
        featureKey: 'admin.shopCashback.page',
      },
    ]

    const selectProductConfig = [
      {
        label: '全部商品 (可選擇排除部分商品)',
        value: 'EXCLUDE',
        chooseLabel: '選擇排除商品',
      },
      {
        label: '只針對特定商品',
        value: 'INCLUDE',
        chooseLabel: '選擇特定商品',
      },
    ]
    const rewardType = ref(typeSettingConfig[0].value)
    const changeTypeHandler = (data) => {
      rewardType.value = data
    }

    const findActivity = async () => {
      loading.value = true
      const [res, err] = await FindRewardActivity({
        shopId: shopId.value,
        id: activityId.value,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      activityData.value = res
      rewardType.value = res.rewardType
    }

    const createRewardActivity = async (payload) => {
      loading.value = true
      const [, err] = await CreateRewardActivity({
        shopId: shopId.value,
        postBody: {
          ...payload,
        },
      })
      if (err) throw err
      window.$message.success(notifyMessage.createSuccess)
    }

    const updateRewardActivity = async (payload) => {
      loading.value = true
      const [, err] = await UpdateRewardActivity({
        shopId: shopId.value,
        id: activityId.value,
        postBody: {
          ...payload,
        },
      })
      if (err) throw err
      window.$message.success(notifyMessage.updateSuccess)
    }

    const checkProduct = (data) => {
      const { sourceItemsSettings } = data
      const operator = sourceItemsSettings[0].operator
      const sourceItemIds = sourceItemsSettings[0].sourceItemIds
      if (operator === 'INCLUDE' && sourceItemIds.length <= 0) {
        showWarningDialog.value = true
        return false
      }
      return true
    }

    const onSubmit = async () => {
      const allPass = await FormsContext.validateAll()
      if (allPass) {
        const data = FormsContext.composFormData()
        const postBody = {
          ...data,
          source: 'MEMBER_STORE_ORDER',
          sourceItemsOperator: 'OR',
        }
        if (!checkProduct(postBody)) return
        try {
          if (!activityId.value) await createRewardActivity(postBody)
          if (activityId.value) await updateRewardActivity(postBody)
          router.push({ name: 'MemberShopFeedbackActivity' })
        } catch (error) {
          window.$message.error(error)
          loading.value = false
        }
      }
    }

    const getProductCount = async () => {
      const [res, err] = await GetMemberShopProductCount({ shopId: shopId.value })
      if (err) throw err
      return res.count
    }

    // 取得所有商城商品
    const getAllStoreProducts = async () => {
      let max
      try {
        max = await getProductCount()
      } catch (error) {
        window.$message.error(error)
        return
      }
      const config = {
        shopId: shopId.value,
        start: 0,
        limit: 100,
      }
      const [res, err] = await getAllDataFromApi(
        max,
        GetMemberShopProduct,
        config,
        true,
      )
      if (err) return window.$message.error(err)
      storeProducts.value = res
    }

    onMounted(async () => {
      await getAllStoreProducts()
      if (activityId.value) await findActivity()
    })

    return {
      onCancel,
      onSubmit,
      loading,
      FormsContext,
      typeSettingConfig,
      changeTypeHandler,
      rewardType,
      activityData,
      storeProducts,
      selectProductConfig,
      showWarningDialog,
    }
  },
})
</script>

<style lang="postcss" scoped>
.member-shop-feedback-activity-edit {
  @apply flex flex-col gap-[20px];
}
.form-wrapper {
  @apply px-[32px] py-[24px];
}
</style>
